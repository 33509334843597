import React, { FormEvent, useState, useEffect } from 'react';
import { Grid, Skeleton, TextField, InputAdornment, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import {
  StyledLogo,
  CenteredSquare,
  Form,
  LoginContainer,
  SubmitButton,
  LogoContainer,
  LoginTitleContainer,
  LoginTermosDeUsoContainer,
  LoginTermosDeUsoLink,
  LoginEsqueciContainer,
  LoginErrorContainer,
  LoginEsqueciLink,
} from './style';
import { useTheme } from 'styled-components';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface IProps {
  loading: boolean;
  loginError: boolean;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => any;
}

const LoginComponent = ({ loading, loginError, handleSubmit }: IProps) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  useEffect(() => {
    localStorage.setItem('flagTrocaSenha', '')
  }, []);

  return (
    <LoginContainer>
      <CenteredSquare>
        <LogoContainer>
          <StyledLogo />
        </LogoContainer>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LoginTitleContainer style={{ color: theme.loginButtonBgc }}>Acesse sua conta</LoginTitleContainer>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="email"
                name="email"
                sx={{
                  '& :-webkit-autofill': {
                    WebkitBoxShadow: `0 0 0 20px ${theme.loginBGColor} inset`,
                    WebkitTextFillColor: theme.logininputColor,
                    WebkitBorderBeforeStyle: 'none',
                    WebkitBackgroundClip: 'text',
                  },
                  '& :-webkit-autofill:focus': {
                    WebkitBoxShadow: `0 0 0 20px ${theme.loginBGColor} inset`,
                    WebkitTextFillColor: theme.logininputColor,
                    WebkitBorderBeforeStyle: 'none',
                    WebkitBackgroundClip: 'text',
                  },
                  '& :-webkit-autofill:hover': {
                    WebkitBoxShadow: `0 0 0 20px ${theme.loginBGColor} inset`,
                    WebkitTextFillColor: theme.logininputColor,
                    WebkitBorderBeforeStyle: 'none',
                    WebkitBackgroundClip: 'text',
                  },
                  input: { color: theme.logininputColor },
                }}
                autoFocus
                label={
                  <span style={{ color: theme.logininputColor }}>
                    <FontAwesomeIcon icon={faUser as IconProp} style={{ marginRight: 12 }} />
                    E-mail
                  </span>
                }
                fullWidth
                variant="standard"></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{ color: theme.logininputColor }}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& :-webkit-autofill': {
                    WebkitBoxShadow: `0 0 0 20px ${theme.loginBGColor} inset`,
                    WebkitTextFillColor: theme.logininputColor,
                    WebkitBorderBeforeStyle: 'none',
                    WebkitBackgroundClip: 'text',
                  },
                  '& :-webkit-autofill:focus': {
                    WebkitBoxShadow: `0 0 0 20px ${theme.loginBGColor} inset`,
                    WebkitTextFillColor: theme.logininputColor,
                    WebkitBorderBeforeStyle: 'none',
                    WebkitBackgroundClip: 'text',
                  },
                  '& :-webkit-autofill:hover': {
                    WebkitBoxShadow: `0 0 0 20px ${theme.loginBGColor} inset`,
                    WebkitTextFillColor: theme.logininputColor,
                    WebkitBorderBeforeStyle: 'none',
                    WebkitBackgroundClip: 'text',
                  },
                  input: { color: theme.logininputColor },
                }}
                label={
                  <span style={{ color: theme.logininputColor }}>
                    <FontAwesomeIcon icon={faLock as IconProp} style={{ marginRight: 12 }} />
                    Senha
                  </span>
                }
                fullWidth
                variant="standard"></TextField>
            </Grid>
            <Grid item xs={12}>
              <LoginEsqueciContainer>
                <LoginEsqueciLink to="/EsqueciMinhaSenha">Esqueci minha senha</LoginEsqueciLink>
              </LoginEsqueciContainer>
            </Grid>
            {!loginError ? null : (
              <Grid item xs={12}>
                <LoginErrorContainer style={{ color: theme.fontColorMenu }}>
                  Falha no login. Usuário ou senha inválidos.
                </LoginErrorContainer>
              </Grid>
            )}
            <Grid item xs={12}>
              <SubmitButton type="submit">
                {loading ? <Skeleton width={60} height={24} /> : 'Entrar'}
              </SubmitButton>
            </Grid>
            <Grid item xs={12}>
              <LoginTermosDeUsoContainer>
                Ao logar, afirmo que aceito os{' '}
                <LoginTermosDeUsoLink
                  to="https://www.comdinheiro.com.br/Download/20231110_TERMOS%20DE%20USO%20-%20COMDINHEIRO%20-%20VF.pdf"
                  target="_blank">
                  termos de uso
                </LoginTermosDeUsoLink>
              </LoginTermosDeUsoContainer>
            </Grid>
          </Grid>
        </Form>
      </CenteredSquare>
    </LoginContainer>
  );
};

export default LoginComponent;
