export const fontFamily = 'Lato';

export const backgroundColor = '#F8F8F8';
export const fontColorMenu = '#F8F8F8';
export const titleFontColor = '#075558';
export const menuIconsColor = 'white';
export const iconsColor = '#075558';
export const fontColor = '#9A9F9E';
export const dropSelectorColor = '#39393F';
export const disabledColor = '#00000061';
export const tabGradientColor = '#F8F8F8' //Adicionar cor ao Side Menu dependendo do cliente

export const loginColor = 'white';
export const logininputColor = 'white';
export const loginErrorColor = 'red';
export const loginTermosDeUsoColor = '#E7E7E7';
export const loginBoxBGColor = '#111111';
export const loginBGColor = '#111111';
export const loginButtonBgc = '#1CB2B9';
export const loginButtonColor = '#FFFFFF';
export const loginBorder = 'none';

export const topMenuBoxShadow =
  '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)';

export const barChartColor = '#f1656c';
export const lineChartColors = [
  '#f1656c',
  '#203864',
  '#2f5597',
  '#8faadc',
  '#385723',
  '#548235',
  '#a9d18e',
  '#c55a11',
  '#f4b183'
];

export const chartColors = [
  '#203864',
  '#2f5597',
  '#8faadc',
  '#385723',
  '#548235',
  '#a9d18e',
  '#c55a11',
  '#f4b183'
]

export const chartLighterColors = [
  '#e3e6ea',
  '#e5e8ef',
  '#eef1f6',
  '#e6e9e3',
  '#e8ede5',
  '#f1f5ee',
  '#f4e9e2',
  '#f9f9f8'
];

export const chartLightColors = [
  '#ccd1da',
  '#cfd7e4',
  '#e3e8f2',
  '#d1d7cd',
  '#d7e0d1',
  '#e8f0e2',
  '#edd8c9',
  '#f7e9e0'
];

export const defaultLegendColor = '#c2c2c2';
export const fontSizeTitle = '24px';
export const xsFontSize = '10px';
export const smFontSize = '12px';
export const mdFontSize = '16px';
export const lgFontSize = '20px';
export const xlFontSize = '28px';
export const lineHeight125 = '125%';

export const lineColor = '#BEBEBE';
export const bgcMain = '#222222';
export const bgcMenuDivider = '#075558';
export const bgcSecondary = '#FFFFFF';
export const borderRightMenu = '1px solid #075558';

export const orange = '#EF770C';
export const red = '#EF0C0C';
export const primaryColor = '#193493';
export const lightBlue = '#23B0DC';
export const positiveGreen = '#26A69A';
export const negativeRed = '#e73c3c';

export const tableCellColor = '#9A9F9E';
export const tableCellDarkerColor = '#68706E';

export const borderRadius = '10px';

export const xsPadding = '8px';
export const smPadding = '16px';
export const mdPadding = '24px';
export const lgPadding = '32px';
export const xlPadding = '40px';

export const xsMargin = '8px';
export const smMargin = '16px';
export const mdMargin = '24px';
export const lgMargin = '32px';
export const xlMargin = '40px';

export const mobileS = '320px';
export const mobileM = '375px';
export const mobileL = '425px';
export const mobileXL = '490px';
export const tablet = '768px';
export const laptop = '1024px';
export const laptopL = '1440px';
export const desktop = '2560px';
